<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="showPageLoader || !storeInitialized"
      class="fixed bg-slate-900/30 w-full h-full inset-0 z-100 backdrop-blur-sm flex items-center justify-center"
    >
      <div class="flex items-center justify-center w-20 h-20 fixed top-1/2 left-1/2 z-50 -translate-y-1/2 -translate-x-1/2 p-3 fade-in-delayed">
        <Spinner :has-logo="false"/>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();
const {
  showPageLoader,
  storeInitialized,
} = storeToRefs(uiStore);
</script>
